export const paths = {
  home: '/',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  contact: '/contact-us',
  aboutUs: '/about-us',
  page404: '/404',
};
