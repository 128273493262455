export const testimonials = [
  {
    name: 'Joe Achkar',
    postedDate: '2025-03-19',
    ratingNumber: 5,
    avatarUrl: '',
    content: `Excellent Work! Thanks a lot!`,
  },
  {
    name: 'Michel Mardini',
    postedDate: '2025-03-19',
    ratingNumber: 5,
    avatarUrl: '',
    content: `Cartify transformed our e-commerce process, streamlining our checkout and boosting conversions.`,
  },
  {
    name: 'Michel Smeha',
    postedDate: '2025-03-19',
    ratingNumber: 5,
    avatarUrl: '',
    content: `Partnering with Cartify has increased our online sales by optimizing every aspect of our storefront.`,
  },
  {
    name: 'Stephanie Atallah',
    postedDate: '2025-03-19',
    ratingNumber: 5,
    avatarUrl: '',
    content: `Cartify’s excellent support and intuitive platform have greatly improved our customer experience.`,
  },
];
