import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
// import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import { MotionViewport, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function AboutWhat() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}
    >
      <Grid container columnSpacing={{ md: 3 }} alignItems="flex-start">
        {mdUp && (
          <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ pr: { md: 7 } }}>
            <Grid xs={6}>
              <m.div variants={varFade().inUp}>
                <Image
                  alt="chef-love"
                  src="/assets/images/about/chef-love.png"
                  ratio="1/1"
                  sx={{ borderRadius: 3, boxShadow: shadow }}
                />
              </m.div>
            </Grid>

            <Grid xs={6}>
              <m.div variants={varFade().inUp}>
                <Image
                  alt="smarter"
                  src="/assets/images/about/smarter.png"
                  ratio="1/1"
                  sx={{ borderRadius: 3, boxShadow: shadow }}
                />
              </m.div>
            </Grid>
          </Grid>
        )}

        <Grid xs={12} md={6} lg={5}>
          <m.div variants={varFade().inRight}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              What is Cartify?
            </Typography>
          </m.div>

          <m.div variants={varFade().inRight}>
            <Typography
              sx={{
                textAlign: 'justify',
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
              }}
            >
              At Cartify, we understand the unique challenges of the food and beverage (F&B)
              industry. Whether you&apos;re a restaurant, café, caterer, or food supplier, our
              platform is built to streamline your ordering process, enhance efficiency, and ensure
              seamless transactions between operators and suppliers.
            </Typography>
          </m.div>

          <m.div variants={varFade().inRight}>
            <Typography
              sx={{
                marginTop: 2,
                textAlign: 'justify',
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
              }}
            >
              Cartify is a specialized B2B platform designed exclusively for F&B businesses. We
              bridge the gap between food suppliers and operators, offering a fast, reliable, and
              secure way to manage orders, track deliveries, and handle payments—all in one place.
            </Typography>
          </m.div>

          {/* // TODO!: GI Add button request demo */}
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}
