import { useScroll, m } from 'framer-motion';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Stack, Container, Grid, Typography } from '@mui/material';
import { bgGradient } from 'src/theme/css';

// components
import ScrollProgress from 'src/components/scroll-progress';

import { MotionViewport, varFade } from 'src/components/animate';
// import { useResponsive } from 'src/hooks/use-responsive';
import HomeHero from '../home-hero';
import HomeMinimal from '../home-minimal';

// ----------------------------------------------------------------------

export default function HomeView() {
  const { scrollYProgress } = useScroll();
  const theme = useTheme();
  // const mdUp = useResponsive('up', 'md');

  return (
    <>
      <ScrollProgress scrollYProgress={scrollYProgress} />

      <HomeHero />

      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <HomeMinimal />

        <Container
          component={MotionViewport}
          sx={{
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          {/* Background Image Section */}
          <Box
            sx={{
              height: { md: 560 },
              width: '100vw',
              overflow: 'hidden',
              position: 'relative',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: 'url(/assets/images/background-home.png)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'calc(-50vw + 50%)',
              marginRight: 'calc(-50vw + 50%)',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent',
              }}
            />

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: '100%',
                position: 'relative',
                zIndex: 10,
              }}
            >
              <m.div variants={varFade().inUp} />
            </Stack>
          </Box>

          <Box sx={{ py: { xs: 5, md: 10 } }} />

          {/* Vision Section */}
          <Grid container columnSpacing={{ md: 3 }} alignItems="flex-start">
            <Grid item xs={12} md={6} lg={6}>
              <m.div variants={varFade().inRight}>
                <Typography variant="h2" sx={{ mb: 3 }}>
                  Our Vision
                </Typography>
              </m.div>

              <m.div variants={varFade().inRight}>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
                  }}
                >
                  Cartify is a revolutionary mobile app designed to transform the way the food and
                  beverage industry manages its supply chain. It serves as a comprehensive platform,
                  connecting operators with reliable suppliers and streamlining the entire
                  procurement process. With Cartify, we aim to empower businesses with a modern,
                  efficient, and user-friendly solution that simplifies inventory management and
                  enhances operational efficiency.
                </Typography>
              </m.div>

              <m.div variants={varFade().inRight}>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
                  }}
                >
                  Our vision is to be the ultimate partner for every operator in the food and
                  beverage sector. We aim to revolutionize the supply chain, making it efficient,
                  transparent, and seamless. With Cartify, we envision a future where businesses can
                  focus on what they do best – providing exceptional culinary experiences to their
                  customers.
                </Typography>
              </m.div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <m.div variants={varFade().inLeft}>
                <Box
                  component="img"
                  src="/assets/images/about/chain.png"
                  alt="Vision"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 2,
                    boxShadow: theme.customShadows.z24,
                  }}
                />
              </m.div>
            </Grid>
          </Grid>

          <Box sx={{ height: 120 }} />

          {/* Journey Section */}
          <Grid container sx={{ mb: 4 }}>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <m.div variants={varFade().inLeft}>
                <Typography variant="h2" sx={{ mb: 3 }}>
                  Join Our Journey
                </Typography>
              </m.div>

              <m.div variants={varFade().inLeft}>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
                  }}
                >
                  Whether you are a small cafe or a bustling restaurant chain, Cartify welcomes you
                  with open arms. Join our community of operators who are embracing the future of
                  food and beverage supply. With Cartify as your partner, the possibilities are
                  endless. Let&apos;s embark on this journey together – one where innovation,
                  quality, and passion converge to redefine the way you run your business. Discover
                  the true potential of your establishment with Cartify today!
                </Typography>
              </m.div>
            </Grid>
          </Grid>

          {/* Advertisement Section */}
          <Stack
            alignItems="center"
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              ...bgGradient({
                direction: '135deg',
                startColor: theme.palette.primary.main,
                endColor: theme.palette.primary.dark,
              }),
              borderRadius: 2,
              pb: { xs: 5, md: 0 },
              mb: 10,
            }}
          >
            <Stack component={m.div} variants={varFade().inUp} alignItems="center">
              <Box
                component={m.img}
                animate={{
                  y: [-20, 0, -20],
                }}
                transition={{ duration: 4, repeat: Infinity }}
                alt="logo"
                src="/assets/images/home/logo-single2.webp"
                sx={{ maxWidth: 460 }}
              />
            </Stack>

            <Box
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
              }}
            >
              <Box
                component={m.div}
                variants={varFade().inDown}
                sx={{ color: 'common.white', mb: 5, typography: 'h2' }}
              >
                Get started with
                <br /> Cartify today
              </Box>

              {/* <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent={{ xs: 'center', md: 'flex-start' }}
                spacing={2}
              >
                <m.div variants={varFade().inRight}>
                  <Button
                    color="inherit"
                    size="large"
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    href={paths.minimalUI}
                    sx={{
                      color: 'grey.800',
                      bgcolor: 'common.white',
                    }}
                  >
                    Request a demo
                  </Button>
                </m.div>
              </Stack> */}
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

function TextAnimate({ text, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  variants: PropTypes.object,
};
