// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  // {
  //   title: 'Privacy Policy',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.privacyPolicy,
  // },
  // {
  //   title: 'Terms And Conditions',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.termsAndConditions,
  // },
  {
    title: 'About Us',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.aboutUs,
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.contact,
  },
];
