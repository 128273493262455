import { useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router';
// layouts
import MainLayout from 'src/layouts/main';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import ComingSoonPage from 'src/pages/coming-soon';
import ContactPage from 'src/pages/contact-us';
import HomePage from 'src/pages/home';
import AboutUsPage from 'src/pages/about-us';
import NotFoundPage from 'src/pages/404';
import { paths } from '../paths';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: paths.home,
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    {
      path: paths.privacyPolicy,
      element: (
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      ),
    },
    {
      path: paths.termsAndConditions,
      element: (
        <MainLayout>
          <TermsAndConditions />
        </MainLayout>
      ),
    },
    {
      path: paths.contact,
      element: (
        <MainLayout>
          <ContactPage />
        </MainLayout>
      ),
    },
    {
      path: paths.aboutUs,
      element: (
        <MainLayout>
          <AboutUsPage />
        </MainLayout>
      ),
    },
    {
      path: paths.comingSoon,
      element: (
        <MainLayout>
          <ComingSoonPage />
        </MainLayout>
      ),
    },
    {
      path: paths.page404,
      element: (
        <MainLayout>
          <NotFoundPage />
        </MainLayout>
      ),
    },
    {
      path: '*',
      element: <Navigate to={paths.page404} />,
    },
  ]);
}
